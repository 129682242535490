/* eslint-disable ts/ban-ts-comment */
import { camelCase } from 'lodash-es'
import type { DayRedBlackResponse, RedBlackResponse } from '@/server/types'
import type { RedBlackDataSeg } from '@/shared/types'

/**
 * 红黑榜数据格式化, 将red|black开头的数据转换为{red: {}, black: {}}的格式
 *
 */
export function formatRedBlankData(data: RedBlackResponse | DayRedBlackResponse): RedBlackDataSeg {
  const newData: RedBlackDataSeg = {
    id: -1,
    red: {},
    black: {},
  }
  const segKey = ['red', 'black']
  const dataKeys = Object.keys(data)

  let isSegKey = false
  let currentSeg = 0
  dataKeys.forEach((key) => {
    for (const seg in segKey) {
      isSegKey = !!key.match(segKey[seg])
      currentSeg = Number.parseInt(seg)
      if (isSegKey)
        break
    }
    if (isSegKey) {
      const newKey = camelCase(key.replace(segKey[currentSeg], ''))
      // @ts-expect-error
      newData[segKey[currentSeg]][newKey] = data[key]
    }
    else {
      // @ts-expect-error
      newData[key] = data[key]
    }
    isSegKey = false
  })
  return newData
}
