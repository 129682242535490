<script setup lang='ts'>
import { timeFormat } from '@qctsw/utils'
import { APP_ROUTER } from '@qctsw/common'
import type { DayRedBlackParams, RedBlackResponse } from '@/server/types'
import { formatRedBlankData } from '@/shared/function'
import { logicRedBlackContainer } from '@/logic/home/weekRedBlack'

const props = withDefaults(defineProps<{
  data: RedBlackResponse
  dayParams: DayRedBlackParams
}>(), {})

const itemKeyList = ['red', 'black']
const weekItem = computed(() => formatRedBlankData(props.data))

const {
  state,
  showDay,
  weekDayList,
  getWeekDayList,
} = logicRedBlackContainer(props.dayParams)
</script>

<template>
  <div class="border-1 border-gray-2 rounded-md">
    <div class="flex border-b-1 border-gray-2">
      <div v-for="(v, i) in itemKeyList" :key="i" class="border-r-1 border-slate-2 last:border-r-0 flex-grow min-w-0 w-50% flex-shrink-0">
        <RedBlackItem
          v-if="weekItem && Object.keys(weekItem).includes(v) && weekItem[v]"
          :item="weekItem[v]" :type="!i ? 'red' : 'black'"
        />
      </div>
    </div>
    <NCollapseTransition :show="showDay">
      <UtilLoadState :state="state" :reload-handler="getWeekDayList" common-class="min-h-60">
        <div v-if="weekDayList && weekDayList.length" class="p-4 text-3.25">
          <div class="grid grid-cols-2 text-4 font-bold">
            <div class="pl-14 text-red-6">
              红榜
            </div>
            <div class="pl-14 text-slate-9">
              黑榜
            </div>
          </div>
          <NuxtLink
            v-for="(v, i) in weekDayList" :key="i" :to="APP_ROUTER.newsDetail(v.articleId)"
            class="flex items-center relative pt-4 text-3.5"
          >
            <span class="date text-gray-5 absolute font-mono">
              {{ timeFormat(v.createTime, 'MM/DD') }}
            </span>
            <span v-for="(key, index) in itemKeyList" :key="index" class="pl-14 inline-block w-full">
              <span class="grid grid-cols-4 text-gray-5">
                <NEllipsis line-clamp="1" :class="[key === 'red' ? 'text-red-6' : 'text-slate-9']">
                  {{ v[key].vendorName }}
                </NEllipsis>
                <span class="">投诉量/宗：<span font-mono>{{ v[key].total }}</span></span>
                <span>解决率：<span font-mono>{{ Math.floor((v[key].finishRate ?? 0) * 100) }}%</span></span>
                <span>回复率：<span font-mono>{{ Math.floor((v[key].replyRate ?? 0) * 100) }}%</span></span>
              </span>
            </span>
          </NuxtLink>
        </div>
        <BasicEmpty v-else />
      </UtilLoadState>
    </NCollapseTransition>
    <slot name="button">
      <button
        type="button"
        class="text-center cursor-pointer text-3.5 block w-full py-sm "
        :class="[showDay ? 'border-t-1 border-gray-2' : '']"
        @click="showDay = !showDay"
      >
        {{ showDay ? '收起' : '查看更多' }}
      </button>
    </slot>
  </div>
</template>

<style lang='scss' scoped>
.red-black-item {
  padding-left: 70px;
  width: 600px;

  &:last-child {
    padding-left: 50px;
  }

  &__info {
    &>div {
      width: 25%;
    }
  }
}
</style>
