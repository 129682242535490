import type { DayRedBlackParams, DayRedBlackResponse, RedBlackResponse } from '@/server/types'
import { getDayRedBlackApi, getWeekRedBlackApi } from '@/server'
import type { RedBlackDataSeg } from '@/shared/types'
import { LOAD_STATE } from '@/shared/types'
import { formatRedBlankData } from '@/shared/function'

export function logicHomeWeekRedBlack() {
  const state = ref(LOAD_STATE.INIT)
  const data = ref<RedBlackResponse | null>(null)

  async function getWeekRedBlack() {
    const res = await useLoadState({
      state,
      key: '红黑榜周榜',
      fn: getWeekRedBlackApi,
    })
    if (res && res.data)
      data.value = res.data
  }

  return {
    state,
    data,
    getWeekRedBlack,
  }
}

export function logicRedBlackContainer(params: DayRedBlackParams) {
  const state = ref(LOAD_STATE.INIT)
  const showDay = ref(false)
  const weekDayList = ref<RedBlackDataSeg[]>([])
  async function getWeekDayList() {
    const res = await useLoadState({
      state,
      fn: () => getDayRedBlackApi(params),
    })
    if (res && res.data)
      weekDayList.value = res.data.map(item => formatRedBlankData(item))
  }

  useActivatedWatch(() => [
    watch(showDay, (nv) => {
      if (nv && !weekDayList.value.length)
        getWeekDayList()
    }),
  ])

  return {
    state,
    showDay,
    weekDayList,
    getWeekDayList,
  }
}
